* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding-bottom: 20px;
  background-color: #e3e3e3;
}

body,
button,
input,
select,
option,
textarea {
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}